import type {
    DashboardIslamicCenter, IslamicCenterEidAlAdhaPrayerTiming, IslamicCenterEidAlFitrPrayerTiming,
    IslamicCenterFullTimeSchoolTypeByGenders, IslamicCenterJummahPrayerTiming, IslamicCenterPrayerTiming,
    IslamicCenterQuota,
    IslamicCenterReportType
} from "~/types";
import defaultLocations from "~/data/default_locations.json";

interface IslamicCentersState {
    current_islamic_centers_search_location: object | null,
    default_well_known_locations: object[],
    current_dashboard_islamic_center: DashboardIslamicCenter | null,
    current_dashboard_islamic_center_expiry: number | null,
    current_dashboard_islamic_center_images: string[],
    current_dashboard_islamic_center_images_expiry: number | null,
    is_searching: boolean,
    current_islamic_centers_search_query: string,
    islamic_centers_full_time_school_type_by_gender: IslamicCenterFullTimeSchoolTypeByGenders[]
    islamic_centers_full_time_school_type_by_gender_expiry: number | null,
    islamic_center_report_types: IslamicCenterReportType[],
    islamic_center_report_types_expiry: number | null,
    islamic_center_quota: IslamicCenterQuota | null,
    islamic_center_quota_expiry: number | null,
    is_showing_search_suggestions: boolean,
    default_search_radius: number
    default_search_sort_by: string
    caching_time: number;
}

export const useIslamicCenters = defineStore('islamic-centers', {
    state: (): IslamicCentersState => {
        return {
            current_islamic_centers_search_location: {
                id: "place.9607189446701850",
                type: "Feature",
                place_type: ["place"],
                relevance: 1,
                properties: {wikidata: "Q1297"},
                text: "Chicago",
                place_name: "Chicago, Illinois, United States",
                bbox: [-87.931085223, 41.625740009, -87.507792006, 42.023137],
                center: [-87.6244, 41.8756],
                geometry: {type: "Point", coordinates: [-87.6244, 41.8756]},
                context: [
                    {
                        id: "district.8754923997749290",
                        wikidata: "Q108418",
                        text: "Cook County"
                    },
                    {
                        id: "region.10854263468358810",
                        wikidata: "Q1204",
                        short_code: "US-IL",
                        text: "Illinois"
                    },
                    {
                        id: "country.19678805456372290",
                        wikidata: "Q30",
                        short_code: "us",
                        text: "United States"
                    }
                ]
            },
            default_well_known_locations: defaultLocations,
            current_dashboard_islamic_center: null,
            current_dashboard_islamic_center_expiry: null,
            current_dashboard_islamic_center_images: [],
            current_dashboard_islamic_center_images_expiry: null,
            is_searching: false,
            current_islamic_centers_search_query: '',
            islamic_centers_full_time_school_type_by_gender: [],
            islamic_centers_full_time_school_type_by_gender_expiry: null,
            islamic_center_report_types: [],
            islamic_center_report_types_expiry: null,
            islamic_center_quota: null,
            islamic_center_quota_expiry: null,
            is_showing_search_suggestions: false,
            default_search_radius: 100,
            default_search_sort_by: "latest",
            caching_time: 1000 * 10,
        }
    },
    actions: {
        setCurrentIslamicCentersLocation(location: object) {
            this.current_islamic_centers_search_location = location;
        },
        clearCurrentIslamicCentersLocation() {
            this.current_islamic_centers_search_location = null;
        },
        setCurrentDashboardIslamicCenter(dashboardIslamicCenter: DashboardIslamicCenter) {
            this.current_dashboard_islamic_center = dashboardIslamicCenter;
            this.current_dashboard_islamic_center_expiry = Date.now();
        },
        clearCurrentDashboardIslamicCenter() {
            this.current_dashboard_islamic_center = null;
            this.current_dashboard_islamic_center_expiry = null;
        },
        isCurrentDashboardIslamicCenterValid() {
            if (this.current_dashboard_islamic_center_expiry != null) {
                return ((Date.now() - this.current_dashboard_islamic_center_expiry) < this.caching_time);
            }
            return true;
        },
        setCurrentDashboardIslamicCenterPrayerTiming(islamicCenterPrayerTiming: IslamicCenterPrayerTiming) {
            if (this.current_dashboard_islamic_center) {
                console.log('Setting Timing')
                this.current_dashboard_islamic_center.prayer_timing = islamicCenterPrayerTiming;
                this.current_dashboard_islamic_center_expiry = Date.now();
            }
        },
        /* Jummah Prayer Timings */
        addCurrentDashboardIslamicCenterJummahPrayerTiming(islamicCenterJummahPrayerTiming: IslamicCenterJummahPrayerTiming) {
            if (this.current_dashboard_islamic_center) {
                this.current_dashboard_islamic_center.jummah_prayer_timings.push(islamicCenterJummahPrayerTiming);
                this.current_dashboard_islamic_center_expiry = Date.now();
            }
        },
        updateCurrentDashboardIslamicCenterJummahPrayerTiming(islamicCenterJummahPrayerTiming: IslamicCenterJummahPrayerTiming) {
            if (this.current_dashboard_islamic_center) {
                let index = this.current_dashboard_islamic_center.jummah_prayer_timings.findIndex(jummahPrayerTiming=>{
                    return jummahPrayerTiming.id === islamicCenterJummahPrayerTiming.id;
                });
                if(index>=0){
                    this.current_dashboard_islamic_center.jummah_prayer_timings[index] = islamicCenterJummahPrayerTiming;
                }
                this.current_dashboard_islamic_center_expiry = Date.now();
            }
        },
        deleteCurrentDashboardIslamicCenterJummahPrayerTiming(islamicCenterJummahPrayerTiming: IslamicCenterJummahPrayerTiming) {
            if (this.current_dashboard_islamic_center) {
                let index = this.current_dashboard_islamic_center.jummah_prayer_timings.findIndex(jummahPrayerTiming=>{
                    return jummahPrayerTiming.id === islamicCenterJummahPrayerTiming.id;
                });
                if(index>=0){
                    this.current_dashboard_islamic_center.jummah_prayer_timings.splice(index,1);
                }
                this.current_dashboard_islamic_center_expiry = Date.now();
            }
        },
        /* Jummah Prayer Timings End */
        /* Eid Al Adha Prayer Timings */
        addCurrentDashboardIslamicCenterEidAlAdhaPrayerTiming(islamicCenterEidAlAdhaPrayerTiming: IslamicCenterEidAlAdhaPrayerTiming) {
            if (this.current_dashboard_islamic_center) {
                this.current_dashboard_islamic_center.eid_al_adha_prayer_timings.push(islamicCenterEidAlAdhaPrayerTiming);
                this.current_dashboard_islamic_center_expiry = Date.now();
            }
        },
        updateCurrentDashboardIslamicCenterEidAlAdhaPrayerTiming(islamicCenterEidAlAdhaPrayerTiming: IslamicCenterEidAlAdhaPrayerTiming) {
            if (this.current_dashboard_islamic_center) {
                let index = this.current_dashboard_islamic_center.eid_al_adha_prayer_timings.findIndex(eidAlAdhaPrayerTiming=>{
                    return eidAlAdhaPrayerTiming.id === islamicCenterEidAlAdhaPrayerTiming.id;
                });
                if(index>=0){
                    this.current_dashboard_islamic_center.eid_al_adha_prayer_timings[index] = islamicCenterEidAlAdhaPrayerTiming;
                }
                this.current_dashboard_islamic_center_expiry = Date.now();
            }
        },
        deleteCurrentDashboardIslamicCenterEidAlAdhaPrayerTiming(islamicCenterEidAlAdhaPrayerTiming: IslamicCenterEidAlAdhaPrayerTiming) {
            if (this.current_dashboard_islamic_center) {
                let index = this.current_dashboard_islamic_center.eid_al_adha_prayer_timings.findIndex(eidAlAdhaPrayerTiming=>{
                    return eidAlAdhaPrayerTiming.id === islamicCenterEidAlAdhaPrayerTiming.id;
                });
                if(index>=0){
                    this.current_dashboard_islamic_center.eid_al_adha_prayer_timings.splice(index,1);
                }
                this.current_dashboard_islamic_center_expiry = Date.now();
            }
        },
        /* Eid Al Adha Prayer Timings End */
        /* Eid Al Fitr Prayer Timings */
        addCurrentDashboardIslamicCenterEidAlFitrPrayerTiming(islamicCenterEidAlFitrPrayerTiming: IslamicCenterEidAlFitrPrayerTiming) {
            if (this.current_dashboard_islamic_center) {
                this.current_dashboard_islamic_center.eid_al_fitr_prayer_timings.push(islamicCenterEidAlFitrPrayerTiming);
                this.current_dashboard_islamic_center_expiry = Date.now();
            }
        },
        updateCurrentDashboardIslamicCenterEidAlFitrPrayerTiming(islamicCenterEidAlFitrPrayerTiming: IslamicCenterEidAlFitrPrayerTiming) {
            if (this.current_dashboard_islamic_center) {
                let index = this.current_dashboard_islamic_center.eid_al_fitr_prayer_timings.findIndex(eidAlFitrPrayerTiming=>{
                    return eidAlFitrPrayerTiming.id === islamicCenterEidAlFitrPrayerTiming.id;
                });
                if(index>=0){
                    this.current_dashboard_islamic_center.eid_al_fitr_prayer_timings[index] = islamicCenterEidAlFitrPrayerTiming;
                }
                this.current_dashboard_islamic_center_expiry = Date.now();
            }
        },
        deleteCurrentDashboardIslamicCenterEidAlFitrPrayerTiming(islamicCenterEidAlFitrPrayerTiming: IslamicCenterEidAlFitrPrayerTiming) {
            if (this.current_dashboard_islamic_center) {
                let index = this.current_dashboard_islamic_center.eid_al_fitr_prayer_timings.findIndex(eidAlFitrPrayerTiming=>{
                    return eidAlFitrPrayerTiming.id === islamicCenterEidAlFitrPrayerTiming.id;
                });
                if(index>=0){
                    this.current_dashboard_islamic_center.eid_al_fitr_prayer_timings.splice(index,1);
                }
                this.current_dashboard_islamic_center_expiry = Date.now();
            }
        },
        /* Eid Al Adha Prayer Timings End */
        setCurrentDashboardIslamicCenterImages(imageUrls: string[]) {
            this.current_dashboard_islamic_center_images = imageUrls;
            this.current_dashboard_islamic_center_images_expiry = Date.now();
        },
        clearCurrentDashboardIslamicCenterImages() {
            this.current_dashboard_islamic_center_images = [];
            this.current_dashboard_islamic_center_images_expiry = null;
        },
        isCurrentDashboardIslamicCenterImagesValid() {
            if (this.current_dashboard_islamic_center_images_expiry != null) {
                return ((Date.now() - this.current_dashboard_islamic_center_images_expiry) < this.caching_time);
            }
            return true;
        },
        addImageToCurrentDashboardIslamicCenterImages(imageUrl: string) {
            this.current_dashboard_islamic_center_images.push(imageUrl);
            this.current_dashboard_islamic_center?.images.push(imageUrl);
        },
        removeImageFromCurrentDashboardIslamicCenterImagesAtIndex(index: number) {
            this.current_dashboard_islamic_center_images.splice(index, 1);
            this.current_dashboard_islamic_center?.images.splice(index, 1);
        },


        isSearching() {
            this.is_searching = true;
        },

        isNotSearching() {
            this.is_searching = false;
        },
        setIslamicCentersSearchQuery(islamicCenterSearchQuery: any) {
            this.current_islamic_centers_search_query = islamicCenterSearchQuery;
        },
        clearIslamicCenterSearchQuery() {
            this.current_islamic_centers_search_query = "";
        },
        setIslamicCenterFullTimeSchoolTypeByGenders(islamicCenterFullTimeSchoolTypeByGenders: any) {
            this.islamic_centers_full_time_school_type_by_gender = islamicCenterFullTimeSchoolTypeByGenders;
            this.islamic_centers_full_time_school_type_by_gender_expiry = Date.now();
        },
        clearIslamicCenterFullTimeSchoolTypeByGenders() {
            this.islamic_centers_full_time_school_type_by_gender = [];
            this.islamic_centers_full_time_school_type_by_gender_expiry = null;
        },
        isIslamicCenterFullTimeSchoolTypeByGendersValid() {
            if (this.islamic_centers_full_time_school_type_by_gender_expiry != null) {
                return ((Date.now() - this.islamic_centers_full_time_school_type_by_gender_expiry) < this.caching_time);
            }
            return true;
        },

        setIslamicCenterReportTypes(islamicCenterReportTypes: any) {
            this.islamic_center_report_types = islamicCenterReportTypes;
            this.islamic_center_report_types_expiry = Date.now();
        },
        clearIslamicCenterReportTypes() {
            this.islamic_center_report_types = [];
            this.islamic_center_report_types_expiry = null;
        },
        isIslamicCenterReportTypesValid() {
            if (this.islamic_center_report_types_expiry != null) {
                return ((Date.now() - this.islamic_center_report_types_expiry) < this.caching_time);
            }
            return true;
        },

        setIslamicCenterQuota(islamicCenterQuota: any) {
            this.islamic_center_quota = islamicCenterQuota;
            this.islamic_center_quota_expiry = Date.now();
        },
        clearIslamicCenterQuota() {
            this.islamic_center_quota = null;
            this.islamic_center_quota_expiry = null;
        },
        isIslamicCenterQuotaValid() {
            if (this.islamic_center_quota_expiry != null) {
                return ((Date.now() - this.islamic_center_quota_expiry) < this.caching_time);
            }
            return true;
        },
        showSearchSuggestions() {
            this.is_showing_search_suggestions = true;
        },
        hideSearchSuggestions() {
            this.is_showing_search_suggestions = false;
        }
    },
})